@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap');

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body::-webkit-scrollbar {
  display: none;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}
.scrollbar-hide {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.scrollbar-hide::-webkit-scrollbar {
  display: none !important;
}

/* #sideBar {
  overflow: hidden !important;
} */

.react-datepicker-popper {
  z-index: 9999 !important;
}
.react-datepicker {
  z-index: 50 !important; /* Tablo z-index'inden daha büyük bir değer verin */
}
.react-datepicker__month-container {
  z-index: 50 !important; /* Tablo z-index'inden daha büyük bir değer verin */
}
.scroll::-webkit-scrollbar {
  background-color: '#f5f5f5';
  border-radius: 10px;
  border: 1px solid #111827;
  width: 12px;
}
.scroll::-webkit-scrollbar-thumb {
  background-color: #111827;
  border-radius: 10px;
}
@layer components {
  .paddingX {
    @apply p-2 md:p-8;
  }

  .deneme {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 80%);
  }
  .zindex {
    z-index: 999;
    width: 30px;
  }
  .pageHeading {
    @apply text-sm md:text-xl;
  }

  .subHeading {
    @apply text-base md:text-lg;
  }

  .cardHeading {
    @apply text-base sm:text-lg;
  }

  .tableHeading {
    @apply text-xs sm:text-sm;
  }
}

#react-select-2-input {
  box-shadow: none;
}

.swiper-button-next {
  color: #2d356c !important;
}

.swiper-button-prev {
  color: #2d356c !important;
}

.PhoneInputInput {
  border: none;
  outline: none;
}

.PhoneInputInput:focus {
  box-shadow: none;
}

.no-outline {
  outline: none;
}
.no-outline:focus {
  outline: none;
  box-shadow: none;
}

.custom-checkmark {
  list-style: none;
}

.custom-checkmark li:before {
  color: #1f254b;
  content: ' ✓ ';
}
/* .swiper-wrapper {
  transition-timing-function: linear;
} */
.scrolling-container {
  width: 100%;
  height: 200px; /* Duyuruların görünür yüksekliği */
  overflow: hidden;
  position: relative;
}

/* Kayan içerik stilleri */
.scrolling-content {
  position: absolute;
  animation: scroll 100s linear infinite; /* Kayan animasyon */
}

/* react date picker shipentegra orders page */
.react-datepicker-wrapper {
  width: 100% !important;
  z-index: 40 !important;
}

/* Kayan animasyon */
@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%); /* Yukarı kaydırma */
  }
}
